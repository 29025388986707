.showquiz-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.showquiz-setting-1 {
    display: flex;
    gap: 10px;
    position: relative;
    left: 2rem;
}

.showquiz-setting-1 a {
    text-decoration: none;
    color: #1b6e9f;
}
.showquiz-data {
    display: flex;
}
.showquiz-all-ques-ans {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    min-height: calc(100vh - 170px);
}
.showquiz-data:not(:last-child) {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.showquiz-data .srno {
    flex: 0 0 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f6f9;
    height: 30px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
    color: #03609c;
}
.showquiz-data .showquiz-ques-ans {
    flex: 1;
}
.showquiz-data .showquiz-ques-ans h5 {
    font-size: 14px;
    margin-bottom: 4px;
}
.showquiz-data .showquiz-ques-ans .showquiz-answer-1 {
    padding: 1px 5px;
    font-size: 10px;
    color: green;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #ebf5eb;
    border-radius: 2px;
    position: relative;
    margin-right: 5px;
}
.showquiz-data .showquiz-ques-ans .showquiz-ans-1 {
    display: flex;
    align-items: baseline;
}
.showquiz-data .showquiz-ques-ans .showquiz-answer-1:after {
    content: '';
    position: absolute;
    right: -3px;
    width: 6px;
    height: 6px;
    background-color: #ebf5eb;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}
span.showquiz-pa {
    font-size: 13px;
    color: gray;
    font-weight: 500;
}
.showquiz-setting-1 button {
    display: flex;
    gap: 10px;
}
@media screen and (max-width: 425px){
    
}