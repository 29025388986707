.topplayer-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.topplayer-setting-1 {
    display: flex;
    gap: 10px;
    position: relative;
    left: 2rem;
    font-size: 18px;
}

.topplayer-header {
    display: flex;
    justify-content: center;
    gap: 18rem;
}

.topplayer-header {
    display: flex;
    justify-content: left;
    background-color: white;
    padding: 15px 0;
    border-radius: 5px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}

.topplayer-header h6 {
    position: relative;
    top: 2px;
    font-weight: 600;
}
.topplayer-setting-1 a {
    text-decoration: none;
    color: #1b6e9f;
}
.topplayer-ctgry {
    display: flex;
    gap: 10px;
    position: relative;
    top: 3rem;
    left: 2rem;
    margin-right: 2rem;
}
.topplayer-btn-2 {
    background-color: #0064a5;
    border-radius: 5px;
    padding: 10px;
    font-size: 13px;
    color: white;
}
.topplayer-img img {
    width: 44px;
    height: 44px !important;
    border-radius: 50%;
    object-fit: cover;
    display: block;
}
.topplayer-top-player {
    display: flex;
    align-items: center;
}
.topplayer-main-2 .topplayer-top-player:last-child {
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
}
.topplayer-main-2 .ctgry {
    margin-bottom: 20px;
}
.topplayer-main-2 .topplayer-top-player {
    margin-bottom: 10px !IMPORTANT;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #ddd;
}
.topplayer-scr p {
    font-size: 12px;
    color: #036097;
    font-weight: 500;
}
.topplayer-gift {
    display: flex;
    background-color: orange;
    border-radius: 50px 0px 0px 50px;
    font-size: 14px;
    color: white;
    margin-right: -16px;
    flex-direction: row-reverse;
    align-items: center;
    padding: 5px 8px 5px 4px;
}
.topplayer-gift i {
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 255 255 / 22%);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    flex: 0 0 30px;
}
.topplayer-gift h5 {
    position: relative;
    font-size: 12px;
    font-weight: bold;
}
.topplayer-positions h5 {
    position: relative;
    top: 6rem;
    left: 2rem;
    color: #0064a5;
    font-weight: 500;
    margin-right: 2rem;
}
.topplayer-img-2 img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
.topplayer-player-rank-data-1 {
    display: flex;
    gap: 6rem;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
    padding: 10px;
}
.topplayer-prfl {
    display: flex;
    gap: 1rem;
    position: relative;
    top: 4px;
}
.topplayer-player-rank-data-1 {
    position: relative;
    left: 2rem;
    top: 7rem;
    background: white;
    margin-right: 4rem;
    border-radius: 10px;
}
.topplayer-name-1 {
    font-weight: 500;
    font-size: 14px;
    position: relative;
    top: 6px;
}
.topplayer-scr-1 {
    font-size: 12px;
    position: relative;
    top: 10px;
    left: 40px;
    color: #0064a5;
}
.topplayer-sr-no-1 {
    color: #0064a5;
}
.topplayer-playr-data-1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    height: 530px;
}
.topplayer-main .game_leader {
    margin-top: -50px;
}
.topplayer-player-data {
    display: flex;
    width: 100%;
}
.topplayer-player-data .topplayer-sr-no {
    height: 30px;
    width: 30px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    color: #03609c;
    border-radius: 0 24px 24px 0;
    margin-left: -16px;
    margin-right: 10px;
    padding-right: 4px;
    font-weight: 500;
}
.topplayer-player-data .topplayer-img {
    flex: 0 0 44px;
    margin-right: 7px;
}
.topplayer-player-data .topplayer-nm {
    align-self: center;
}
.topplayer-player-data .topplayer-nm h5 {
    font-weight: bold;
    font-size: 16px;
}
.topplayer-player-data .topplayer-nm p {
    font-size: 12px;
    color: gray;
    font-weight: 500;
}