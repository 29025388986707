.subscribe-quiz-logo {
    text-align: center;
}
.subscribe-quiz-logo img {
    width: 150px;
    display: block;
    margin: 0 auto;
}
.subscribe-option p {
    color: #f7a432;
}
.subscribe-anchor button {
    text-decoration: none;
    color: white;
    background: #0364a4;
    padding: 10px 18px;
    width: 100%;
    border-radius: 7px;
} 
.subscribe-option input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
}
.subscribe-option {
    position: relative;
}
.subscribe-option label {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    transition: all .3s ease;
    cursor: pointer;
}
.subscribe-option label img {
    flex: 0 0 30px;
    max-width: 100%;
    width: 30px;
    margin-right: 10px;
}
.subscribe-option label span {
    font-weight: 500;
}
.subscribe-option label p {
    margin-left: auto;
    margin-bottom: 0;
    margin-right: 40px;
    padding-left: 15px;
}
.subscribe-option label:after {
    content: "\f111";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    font-family: "Font Awesome 6 Free";
    font-weight: 400;
    line-height: normal;
    color: #ddd;
}
.subscribe-choose-button {
    margin-bottom: 10px;
    background-color: #fff;
    padding: 0;
}
.subscribe-option input:checked + label::after {
    /* content: "\f111";
    color: #0364a4;
    font-weight: 900;
    outline: 1px solid;
    padding: 0px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    text-align: center; */
    content: "\f058";
    color: #0364a4;
    font-weight: 900;
}
.subscribe-option input:checked + label {
    border-color: #0164a2;
    color: #0164a2;
}