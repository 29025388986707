.profile-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.profile-sidebar .profile-profile img{
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.profile-full-prfl-cnt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.profile-full-prfl-cnt .profile-prfl-btn {
    flex: 0 0 100%;
    margin-top: 10px;
}
.profile-full-prfl-cnt .profile-profile {
    flex: 0 0 50px;
    max-width: 50px;
    margin-right: 6px;
}
.profile-full-prfl-cnt .profile-prfl-data {
    flex: 1;
}
.profile-full-prfl-cnt .profile-prfl-data h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px;
}
.profile-prfl-btn button {
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid orange;
    color: orange;
    width: 100%;
}
.profile-power i {
    color: orange;
}
.profile-sidebar h5 {
    font-weight: 500;
    font-size: 15px;
}
.profile-full-prfl-cnt .profile-prfl-data p {
    background-color: #f0f6f9;
    display: inline-block;
    padding: 5px 9px;
    line-height: 1.3;
    border-radius: 3px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 500;
    color: #03609c;
    margin-bottom: 0;
}
.profile-sidebar {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}
.profile-cate-img-1 img {
    max-width: 200px;
    width: 100%;
    height: 100px;
    position: relative;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    left: 0px;
}
.profile-cate-cnt-1 h5 {
    font-weight: 500;
    font-size: 16px;
}
.profile-cate-cnt-1 p {
    font-size: 10px;
}
.profile-cate-cnt-1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
}
.profile-category {
    position: relative;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    width: 200px;
   
}
.profile-fav-topics button {
    background: white;
}
.profile-prfl-btn button {
    background: white;
}
.rewards-navbar .profile-power span {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    line-height: normal;
}
.profile-all-catgs h5:hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.profile-footer {
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: relative;
    top: 10rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.profile-body-cnt {
    padding: 16px;
}
.profile-icon-1 {
    position: relative;
    top: 10px;
    height: 0px;
}

.profile-icon-1 a {
    text-decoration: none;
    color: black;
}
.profile-icon-5 a {
    position: relative;
    bottom: 1.5rem;
    text-decoration: none;
    color: black;
}
.profile-icon-5 p {
    position: relative;
    top: 9px;
}
.profile-icon-5 img {
    width: 75px;
    position: relative;
    top: 2px;
    left: 0px;
}
.profile-icon-5 button {
    background: white;
    border-radius: 50px 50px 0 0;
}
.profile-icon-5 button {
    position: relative;
    bottom: 3rem;
}


#progressbar {
    background-color: lightgrey;
    border-radius: 13px;
    padding: 3px;
    position: relative;
    top: 4rem;
    max-width: 332px;
    width: 100%;
}
.profile-full-body {
    padding: 0 2rem;
}
  #progressbar>div {
    background-color: green;
    width: 40%;
    height: 20px;
    border-radius: 10px;
}



.profile-star-img img {
    width: 100px;
    height: 100px;
    position: relative;
    bottom: 3rem;
}
.profile-star-img {
    display: flex;
    justify-content: right;
}
.profile-no {
    display: flex;
    justify-content: right;
    margin-right: 8rem;
    position: relative;
    top: 1rem;
}
.profile-performance-1 {
    background: white;
    border-radius: 15px;
    width: 100%;

}
.profile-performance-data {
    padding: 20px;
}
.profile-xp {
    position: relative;
    top: 3rem;
    left: 5px;
}
.profile-xp h5 {
    font-weight: 500;
}



@media screen and (max-width: 425px) and (min-width: 375px){

    .profile-category {
        width: 150px;
        height: 145px;
    }
    .profile-cate-cnt-1 h5 {
        font-size: 12px;
    }
    .profile-cate-cnt-1 p {
        font-size: 10px;
        position: relative;
        bottom: 1rem;
    }
    .profile-cate-cnt-1 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 2px;
    }
    
}
@media screen and (max-width: 374px) and (min-width: 320px){
    .profile-category {
        width: 150px;
        height: 135px;
    }
    .profile-cate-cnt-1 h5 {
        font-size: 11px;
    }
    .profile-cate-cnt-1 p {
        font-size: 10px;
        position: relative;
        bottom: 1rem;
    }
    .profile-cate-img-1 img {
        height: 90px;
    }
    .profile-cate-cnt-1 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 2px;
    }

}