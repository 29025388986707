.play-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.play_head .nav-prfl {
    flex: 1;
}
.play_head .play-prfl-img {
    flex: 0 0 40px;
    margin-right: 5px;
}
.play-prfl-img img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.play-nav {
    display: flex;
    position: relative;
    padding: 10px;
    background: #0064a2;
    justify-content: space-between;
}
.play-prfl-data {
    color: white;
}
.play_head .play-prfl-data h5 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: revert;
}
.play-prfl-data p {
    font-size: 12px;
    margin-bottom: 0;
}
#progressbar {
    background-color: white;
    border-radius: 13px;
    padding: 3px;
    max-width: 390px;
    width: 100%;
}
.play-full-body {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}
.play-full-body .play-ques-1 {
    background-color: #e0ecf3;
    margin: -16px -16px 0;
    border-radius: 10px 10px 0 0;
    padding: 16px;
    font-size: 14px;
    color: #03609c;
    font-weight: 600;
}
.play-full-body .play-ques-1 p {
    margin-bottom: 0;
    position: relative;
    display: flex;
}
.play-full-body .play-ques-1 p:before {
    content: 'Q:';
    height: 30px;
    width: 30px;
    background-color: #fff;
    flex: 0 0 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 30%;
    margin-right: 5px;
    font-size: 15px;
}
#play-progressbar .progress {
    background-color: rgb(181, 246, 181);
    height: 17px;
    border-radius: 4px;
    position: relative;
    flex: 1;
    margin-left: 7px;
}
div#play-progressbar {
    padding-top: 15px;
    display: flex;
}
.my-progressbar{
    background-color: green;
    width: 40%;
    height: 20px;
    border-radius: 10px;
}
.play-watch i {
    color: #0064a2;
    font-size: 16px;
}

/* .play-click-option button:hover{
    background-color: #ffa200;
    border-style: solid;
    border-color: #0064a2;
} */

.play-option {
    margin-top: 10px;
}
.play-option .play-click-option:not(:last-child) {
    margin-bottom: 7px;
}
.play-option .play-click-option button {
    width: 100%;
    text-align: left;
    padding: 8px 15px;
    border: 1px solid #ddd;
    border-radius: 7px;
    position: relative;
    font-weight: 400;
    color: #333;
    transition: all .3s ease;
}
/* .play-option .play-click-option button:focus {
    color: #fff;
} */
.not-select{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
.play-nav button {
    height: 25px;
    padding: 0 5px;
    background: rgba(0, 0, 0, .5);
    color: white;
    border-radius: 5px;
    margin-top: 20px;
}
.nav-prfl {
    display: flex;
    align-items: center;
}
span.showscore-span-2 {
    margin-left: 5px;
}