.seeall-hm-top {
    background-color: #fff;
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid #eee;
    margin-bottom: 15px;
}
.seeall-hm-top h4 {
    font-size: 18px;
}
.seeall-hm-top .cusbreadcrumb a{
    text-decoration: none !important;
    color: #0163a2;
}
.seeall-hm-top .cusbreadcrumb a:not(:last-child) {
    margin-right: 4px;
}
@media screen and (min-width: 768px) {   
    .seeall-all-catg .home-cate-img-1 img {
        height: 200px;
    }
}
/* .seeall-menu-bar img {
    width: 70px;
    margin-left: 8px;
}
.seeall-logo-1 img {
    width: 60px;
}
.seeall-nav {
    display: flex;   
     justify-content: space-around;
    background-color: whitesmoke;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.seeall-win-sign-1 i {
    position: relative;
    color: #0163a2;
}
.seeall-header {
    display: flex;
    justify-content: center;
}
.seeall-header img {
    max-width: 429px;
    width: 100%;
    height: 175px;
    position: relative;
    top: 2rem;
    border-radius: 25px;
}
.seeall-countdown-1 h6 {
    background-color: orange;
    width: 25px;
    padding: 6px;
    position: relative;
    left: 0rem;
    top: 10px;
    border-radius: 5px;
}
.seeall-timer {
    display: flex;
    gap: 10px;
}
.seeall-cnt-img {
    position: absolute;
    top: 6rem;
    left: 4rem;
}
.seeall-timer hr {
    position: relative;
    top: 13px;
    height: 20px;
}
.seeall-btn-enter button {
    position: relative;
    top: 4.8rem;
    left: 7rem;
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    background: #3fab3f;
    color: white;
}
.seeall-sec-1 {
    display: flex;
    position: relative;
    top: 4rem;
    justify-content: center;
    gap: 18.6rem;
}
.seeall-cate-img-1 img {
    max-width: 200px;
    width: 100%;
    height: 100px;
    position: relative;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    left: 0;
}
.seeall-cate-cnt-1 h5 {
    font-weight: 500;
    font-size: 16px;
}
.seeall-cate-cnt-1 p {
    font-size: 10px;
}
.seeall-cate-cnt-1 {
    display: flex;
    flex-direction: column;
    padding: 5px;
} */
/* .seeall-icon-1 {
    position: relative;
    top: 10px;
    height: 0px;
}

.seeall-icon-1 a {
    text-decoration: none;
    color: black;
}
.seeall-content-1 p {
    position: relative;
    top: -18px;
    color: #8e8fa0;
}
.seeall-icon-5 img {
    width: 45px;
}
.seeall-icon-5 a {
    position: relative;
    bottom: 1.5rem;
    text-decoration: none;
    color: black;
}
.seeall-icon-5 p {
    position: relative;
    bottom: 1px;
}
.seeall-icon-5 img {
    width: 40px;
    position: relative;
    top: 2px;
}
.seeall-profile h3{
    position: relative;
    left: 4rem;
}
.seeall-profile p{
    position: relative;
    left: 4rem;
}

.seeall-profile {
    background-color: #0264a1;
    padding: 20px 0;
    position: relative;
}

.seeall-search-container form {
    position: relative;
    top: 13px;
}
input[type="text"] {
    padding: 8px;
    border: none;
    border-radius: 5px;
}
.seeall-search-container input {
    position: relative;
    bottom: 9px;
}
form i {
    position: relative;
    left: -25px;
    bottom: 3px;
}
.seeall-search-container button {
    border: none;
    background: none;
}
.seeall-win-sign-1 i {
    position: relative;
    top: 18px;
}

span.seeall-i-2 {
    position: relative;
    left: 22px;
}

.seeall-sidebar{
    background: white;
    position: absolute;
    top: 53px;
    left: 0;
    width: 225px;
    height: 697px;
    transition: all 0.5s ease;
}
.seeall-sidebar .seeall-profile img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto;
    object-fit: cover;
}
.seeall-profile {
    background-color: #0264a1;
    padding: 20px 0 75px 0;
    position: relative;
    display: flex;
}
.seeall-quick-btn {
    display: flex;
    justify-content: center;
}
.seeall-quick-btn button {
    padding: 8px 25px;
    background-color: orange;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    bottom: 3rem;
}
.seeall-sidebar .seeall-profile h3 {
    color: white;
    margin-top: 10px;
    position: relative;
    left: 5px;
    font-weight: 500;
}

.seeall-sidebar .seeall-profile p {
    color: rgb(206, 284, 253);
    font-size: 14px;
    position: relative;
    left: -87px;
    top: 2rem;
}
.seeall-sidebar ul li{
    list-style: none;
}
.seeall-sidebar ul li a {
    display: block;
    padding: 20px 30px;
    color: black;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    text-decoration: none;
}
.seeall-quick-btn i {
    position: relative;
    left: -5px;
}
ul {
    position: relative;
    bottom: 35px;
}
.seeall-sidebar ul li a .seeall-icon{
    color: black;
    width: 30px;
    display: inline-block;
}


.seeall-sidebar ul li a:hover,
.seeall-sidebar ul li a:active{
    color: white;
    background-color: #0264a1;
    border-bottom: 2px solid rgb(5, 68, 104);

}

.seeall-sidebar ul li a:hover .seeall-icon,
.seeall-sidebar ul li a:active .seeall-icon{
    color: white;
}

.seeall-sidebar ul li a:hover::before,
.seeall-sidebar ul li a:active::before{
    display: block;
}
.seeall-icon-5 p {
    position: relative;
    top: 8px;
}

.seeall-menu-bar button {
    max-width: 55px;
    width: 100%;
    background-color: whitesmoke;
}

@media screen and (max-width: 500px){
    .seeall-header img {
        width: 85%;
    }
    .seeall-sec-1 {
        gap: 13rem;
        margin: 0 2rem;
    }
    .seeall-category {
        height: 150px;
    }
    
    .seeall-cate-cnt-1 {
        gap: 2px;
        padding: 0 5px;
    }
    .seeall-cate-img-1 img {
        height: 96px;
    }
    section.seeall-footer {
        gap: 2rem;
        top: 10rem;
}
}

@media screen and (max-width: 425px) and (min-width: 375px){
    .seeall-sec-1 {
        gap: 9rem;
        margin: 0 2rem;
    }
    .seeall-category {
        height: 130px;
        width: 150px;
    }
    .seeall-cate-img-1 img {
        height: 75px;
    }
    .seeall-cate-cnt-1 p {
        font-size: 9px;
        position: relative;
        bottom: 6px;
    }
    .seeall-cate-cnt-1 h5 {
        font-weight: 500;
        font-size: 16px;
        position: relative;
        top: 4px;
    }
    
}


@media screen and (max-width: 375px){
    .seeall-header img {
        width: 85%;
    }
    .seeall-sec-1 {
        gap: 10rem;
        margin: 0 2rem;
    }
    .seeall-category {
        height: 110px;
        width: 130px;
    }
   
    .seeall-cate-cnt-1 {
        gap: 2px;
        padding: 0 5px;
    }
    .seeall-cate-img-1 img {
        height: 60px;
    }
    section.seeall-footer {
        gap: 1rem;
}
.seeall-btn-enter button {
    left: 5rem;
}
.seeall-cate-cnt-1 h5 {
    font-weight: 500;
    font-size: 16px;
    position: relative;
    top: 0;
}
.seeall-cate-cnt-1 p {
    font-size: 8px;
    position: relative;
    bottom: 14px;
}

}
@media screen and (max-width: 320px){
    .seeall-header img {
        width: 85%;
    }
    .seeall-sec-1 {
        gap: 8rem;
        margin: 0 2rem;
    }
   
    .seeall-cate-cnt-1 {
        gap: 2px;
        padding: 0 5px;
    }
    
    section.seeall-footer {
        gap: 1rem;
        font-size: 12px;
    }
} */