.settingpage-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.saveBtn .btn-orange{
    background-color: orange;
    border-color: orange;
    color: #fff;
    font-size: 13px;
    padding: 9px 15px;
}
.settingpage-second-sec {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    min-height: calc(100vh - 178px);
}
.settingpage-second-sec button{
    width: 100%;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.settingpage-second-sec button h5 {
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    font-family: var(--f1) !important;
    text-align: left;
    margin-bottom: 14px;
}

.settingpage-btn-1 a {
    text-decoration: none;
    color: white;
    background-color: orange;
    padding: 8px 8px;
    border-radius: 5px;
}
.settingpage-click{
    background-color: #ddd;
}
.settingpage-second-sec .settingpage-container {
    position: relative;
}
.settingpage-click:hover{
    background-color: #918f8f;
}
button.settingpage-click {
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 9px 15px;
    outline: none !important;
    color: black !important;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #ddd !important;
}
.settingpage-links {
    padding: 12px 10.215rem;
    font-size: 12px;
    border: none;
    outline: none;
    color: #fff;
}
.settingpage-list {
    position: absolute;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.3s;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.settingpage-list button {
    display: flex;
    padding: 14px;
    justify-content: space-between;
}
.settingpage-newlist{
    transform: scale(1);
}
.settingpage-links{
    background-color: #3f4346;
}
.settingpage-links:hover{
    background-color: #191a1b;
}
.settingpage-link-1 {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid gray;
}
.settingpage-link-3 {
    border-radius:  0 0 5px 5px;
}
.settingpage-link-2 {
    border-bottom: 1px solid gray;
}

.settingpage-header button {
    background-color: cadetblue;
}
.settingpage-select-button{
    background: none;
}