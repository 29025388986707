.notification-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.topplayer-setting-1 {
    display: flex;
    gap: 10px;
    position: relative;
    left: 2rem;
    font-size: 18px;
}

.topplayer-header {
    display: flex;
    justify-content: center;
    gap: 18rem;
}

.topplayer-header {
    display: flex;
    justify-content: left;
    background-color: white;
    padding: 15px 0;
    border-radius: 5px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
}

.topplayer-header h6 {
    position: relative;
    top: 2px;
    font-weight: 600;
}
.topplayer-setting-1 a {
    text-decoration: none;
    color: #1b6e9f;
}
.topplayer-ctgry {
    display: flex;
    gap: 10px;
    position: relative;
    margin-right: 2rem;
}
/* .custabs {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
    min-height: calc(100vh - 185px);
} */
.custabs {
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: 20px auto 0;
    box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
    min-height: calc(100vh - 190px);
    border-radius: 7px;
    overflow: hidden;
}
.custabs .nav-tabs li.nav-item {
    flex: 1;
}
.custabs .nav-tabs li.nav-item button {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: none;
    border-radius: 0;
    font-size: 11px !important;
    line-height: normal;
    padding: 10px 5px;
    text-transform: uppercase;
    font-weight: 600;
    color: #333;
}
.custabs .nav-tabs li.nav-item button.active {
    background-color: #03609c;
    color: #fff;
}
.custabs .tab-content {
    padding: 0 15px 15px;
}
.custabs .tab-content ul {
    list-style: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
.custabs .notifi_item {
    border-bottom: 1px solid #eee !important;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
}
.custabs .tab-content .notifi_item:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
}
.custabs .notifi_item .notifi-data {
    display: flex;
}
.custabs .notifi_item .notifi-data .sr-no {
    height: 24px;
    width: 24px;
    background-color: rgb(3 96 156 / 8%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 70%;
    flex: 0 0 24px;
    margin-right: 7px;
    font-size: 12px;
    font-weight: 600;
    color: #03609c;
}
.custabs .notifi_item .notifi-data .notfi_dtl {
    flex: 1;
}
.custabs .notifi_item .notifi-data .notfi_dtl h5 {
    font-size: 14px;
    font-family: var(--f1) !important;
    margin-bottom: 0;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}
.custabs .notifi_item .notifi-data .notfi_dtl p {
    margin-bottom: 0 !important;
    padding-top: 2px;
    color: gray;
    font-size: 13px;
}
@media screen and (max-width: 475px){
    .custabs .notifi_item .notifi-data .notfi_dtl h5 {
        font-size: 13px;
    }
}