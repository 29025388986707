.update-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.update_profile label{
    margin-bottom: 4px;
}
.update_profile .form-control{
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    min-height: 42px;
    padding: 8px 15px;
}
.update_profile {
    max-width: calc(100% - 32px);
    margin: 20px auto;
    background-color: #fff;
    padding: 25px;
    border-radius: 7px;
    box-shadow: 0 4px 83px rgb(0 0 0 / 6%);
    border: 1px solid #eee;
    min-height: calc(100vh - 206px);
}
.update-label-class{
    border: 1px dashed #777;
    background-color: #eee;
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
    padding: 15px;
    margin-top: 1px;
}
.update-quiz-logo {
    /* display: flex;
    justify-content: center; */
}
.uploaded_inner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 7px;
    display: block;
}
.uploaded_inner span {
    position: absolute;
    top: -6px;
    right: -6px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eb272f;
    color: #fff;
    border-radius: 50px;
    font-size: 12px;
    cursor: pointer;
    z-index: 9;
}
.uploaded_inner {
    height: 150px;
    width: 150px;
    position: relative;
    margin: 20px auto 0;
}

.update-submit-btn button {
    width: 100%;
    padding: 8px 15px;
    background: #03649b;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    display: block;
    margin-top: 11px;
}