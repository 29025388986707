.invite-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.invite-copy-btn button {
    padding: 10px 20px;
    background: orange;
    color: white;
    border: none;
    border-radius: 5px;
}
.invite-body {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
}
.invite-body .invite-invite-img img {
    max-width: 100%;
    max-height: 170px;
    margin: 0 auto;
    display: table;
}
.invite-body .invite-share {
    text-align: center;
}
.invite-body .invite-share h5 {
    font-weight: bold;
    margin-bottom: 3px;
}
.invite-body .invite-share p {
    color: gray;
    font-size: 13px;
}
.invite-copy-btn {
    margin: 10px 0 10px;
    text-align: center;
}