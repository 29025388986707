.search-main {
    max-width: 575px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}
.search-input{
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;    
}
.search-search-container {
    padding: 16px;
    background-color: #fff;
    max-width: calc(100% - 32px);
    margin: -50px auto 0;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    min-height: calc(100vh - 180px);
}
.search-search-container form {
    position: relative;
}
.search-search-container form input.search-input {
    border: 1px solid #eee;
    border-radius: 7px;
    padding: 10px 15px;
    font-size: 13px;
    color: #000;
    padding-right: 45px;
    box-shadow: none !important;
    outline: none !important;
}
/* input[type="text"] {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
} */

/* form{
    display: flex;
    justify-content: space-around;
} */

/* input[placeholder="Search.."] {
    padding: 1rem;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
} */

.search-search-container form button.search_icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 41.5px;
    height: 100%;
    border-radius: 0 7px 7px 0px;
    background: transparent;
    border-left: 1px solid #eee;
}
.search-search-container form input.search-input:focus {
    border-radius: 7px 7px 0 0;
}
.search-icon-1 {
    position: relative;
    top: 10px;
    height: 0px;
}

.search-icon-1 a {
    text-decoration: none;
    color: black;
}
.search-content-1 p {
    position: relative;
    top: -18px;
    color: #8e8fa0;
}
.search-icon-5 a {
    position: relative;
    bottom: 1.5rem;
    text-decoration: none;
    color: black;
}
.search-icon-5 p {
    position: relative;
    bottom: 1px;
}
.search-icon-5 img {
    width: 40px;
    position: relative;
    top: 2px;
}
.search-profile h3{
    position: relative;
    left: 4rem;
}
.search-profile p{
    position: relative;
    left: 4rem;
}

.search-profile {
    background-color: #0264a1;
    padding: 20px 0;
    position: relative;
}
section.search-footer {
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: absolute;
    background-color: white;
    box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
    width: 100%;
    bottom: 0px;
}

.search-searched-div:not(:last-child) {
    border-bottom: 1px solid #eee;
}
.search-main-container {
    background: #fff;
    padding: 5px 15px;
    border-radius: 0 0 7px 7px;
    position: absolute;
    width: 100%;
    max-height: 220px;
    overflow: auto;
    border: 1px solid #eee;
    border-top: 0;
}
.search-searched-div button {
    background: none !important;
    width: 100%;
    text-align: left;
    padding: 10px 0;
    font-size: 12px;
    color: #000;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.search-searched-div button:hover, .search-searched-div button:focus {
    color: #03609c;
    font-weight: 600;
}
.search-main-container:empty {
    visibility: hidden;
}